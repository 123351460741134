import React from "react"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

import {
  renderSEO,
  ROUTES,
  isBrowser,
  LoadingIndicator,
} from "@lesmills/gatsby-theme-common"

const CheckEmail = React.lazy(() => import("../components/CheckEmail"))

type Props = {|
  pageContext: {
    lang: string,
    data: Object,
  },
|}

const CheckEmailPage = ({ pageContext, data }: Props) => {
  const lang = pageContext.unPublishedLang || pageContext.lang

  if (!data) return null
  const prismicCheckEmailPage = data.prismicCheckEmailPage || {}

  const pageContent = prismicCheckEmailPage.data

  return (
    <>
      {renderSEO(pageContent, ROUTES(lang).CHECK_EMAIL, lang)}
      {isBrowser && (
        <React.Suspense fallback={<LoadingIndicator />}>
          <CheckEmail data={pageContent} />
        </React.Suspense>
      )}
    </>
  )
}

export const query = graphql`
  query($lang: String) {
    prismicCheckEmailPage(lang: { eq: $lang }) {
      data {
        logo {
          alt
          url
        }
        notices_text {
          raw
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        return_to_homepage_text {
          raw
        }
      }
    }
  }
`

export default withPreview(CheckEmailPage)
